body {
  background-color: #e4edef;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.underline {
  text-decoration: underline;
  text-decoration-color: #9fb4a8;
  text-decoration-thickness: 3px;
}

.underline:hover {
  text-decoration-color: #2e856e;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 16px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 16px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 16px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 16px;
}